import React, { useEffect, useState, lazy } from "react";
import * as c from "./constants";
const Dashboard = lazy(() => import('../../pages/Dashboard/Dashboard'));
const DocManagement = lazy(() => import('../../pages/DocManagement/DocManagement'));
const UserManagement = lazy(() => import('../../pages/UserManagement/UserManagement'));
const ManualReview = lazy(() => import('../../pages/ManualReview/ManualReview'));
const AuditLog = lazy(() => import('../../pages/AuditLog/AuditLog'));
const AuditReport = lazy(() => import('../../pages/AuditReport/AuditReport'));
const AdminConfig = lazy(() => import('../../pages/AdminConfig/AdminConfig'));
const SuperAdmin = lazy(() => import('../../pages/SuperAdmin/SuperAdmin'));
const UserProfile = lazy(() => import('../../pages/UserProfile/UserProfile'));
const InfoScreen = lazy(() => import('../../pages/InfoScreen/InfoScreen'));
const CustomUsageReport = lazy(() => import('../../pages/CustomUsageReport/CustomUsageReport'));
const MonthlyReport = lazy(() => import('../../pages/MonthlyReport/MonthlyReport'));
const DocInfo = lazy(() => import('../../pages/SuperAdmin/DocInfo/DocInfo'));
const DocStuck = lazy(() => import('../../pages/SuperAdmin/DocStuck/DocStuck'));
const ConfusionMatrix = lazy(() => import('../../pages/SuperAdmin/ConfusionMatrix/ConfusionMatrix'));
const Accuracy = lazy(() => import('../../pages/SuperAdmin/AccuracyTracker/Accuracy'));
const SubCategory = lazy(() => import('../../pages/SuperAdmin/SubCategoryTable/SubCategoryTable'));
const UserPerformance = lazy(() => import('../../pages/UserPerformance/UserPerformance'));

export const MENU_OPTIONS = [
  {
    "value": c.PAGE_URLS[c.PAGE_INFO],
    "role": [],
    "component": InfoScreen,
    hideNavigationMenu: true, 
  },
  {
    "value": c.PAGE_URLS[c.ADMIN_CONFIG],
    "role": ["Admin"],
    "component": AdminConfig,
  },
  {
    "value": c.PAGE_URLS[c.PAGE_USER_PROFILE],
    "role": [],
    "component": UserProfile,
  },
  {
    "label": "Home",
    "value": c.PAGE_URLS[c.PAGE_DASHBOARD],
    "role": ["Staff", "Admin"],
    "component": Dashboard,
    showMenu: true
  },
  {
    "label": "Data",
    "value": c.PAGE_URLS[c.PAGE_DATA],
    "children": [
      {
        "label": "Doc Management",
        "value": c.PAGE_URLS[c.PAGE_DOC_MGMT],
        "role": ["Staff", "Admin"],
        "component": DocManagement,
        showMenu: true,
        hasOwnPath: true,
        "children": [{
          "label": "Manual Review",
          "value": c.PAGE_URLS[c.PAGE_MANUAL_REVIEW],
          "role": ["Staff", "Admin"],
          "component": ManualReview,
        }]
      },
    ]
  },
  {
    "label": "Admin",
    "value": c.PAGE_URLS[c.PAGE_ADMIN],
    "children": [
      {
        "label": "User Management",
        "value": c.PAGE_URLS[c.PAGE_USER_MGMT],
        "role": ["Admin"],
        "component": UserManagement,
        showMenu: true
      },
      {
        "label": "Manual Process",
        "value": c.PAGE_URLS[c.PAGE_DOC_STUCK],
        "role": ["Admin"],
        "component": DocStuck,
        showMenu: true
      },
    ]
  },
  {
    "label": "Reports",
    "value": c.PAGE_URLS[c.PAGE_REPORTS],
    "children": [
      {
        "label": "Usage Report",
        "value": c.PAGE_URLS[c.PAGE_USAGE_REPORT],
        "children": [
          {
            "label": "Monthly Report",
            "value": c.PAGE_URLS[c.PAGE_MONTHLY_USAGE_REPORT],
            "role": ["Admin"],
            "component": MonthlyReport,
            showMenu: true
          },
          {
            "label": "Custom Report",
            "value": c.PAGE_URLS[c.PAGE_CUSTOM_USAGE_REPORT],
            "role": ["Admin"],
            "component": CustomUsageReport,
            showMenu: true
          },
        ]
      },
      {
        "label": "Performance",
        "value": c.PAGE_URLS[c.PAGE_PERFORMANCE_REPORT],
        "role": ["Admin"],
        "component": UserPerformance,
        showMenu: true
      },
      {
        "label": "Audit",
        "value": c.PAGE_URLS[c.PAGE_AUDIT],
        "children": [
          {
            "label": "Audit Log",
            "value": c.PAGE_URLS[c.PAGE_AUDIT_LOG],
            "role": ["Admin"],
            "component": AuditLog,
            showMenu: true
          },
          {
            "label": "Audit Report",
            "value": c.PAGE_URLS[c.PAGE_AUDIT_REPORT],
            "role": ["Admin"],
            "component": AuditReport,
            showMenu: true
          },
        ]
      },
    ]
  },
  {
    "label": "Super Admin",
    "value": c.PAGE_URLS[c.PAGE_SUPER_ADMIN],
    hasOwnPath: true,
    "component": SuperAdmin,
    "children": [
      {
        "label": "Manual Process",
        "value": c.PAGE_URLS[c.PAGE_DOC_STUCK],
        "role": ["Admin"],
        "component": DocStuck,
      },
      {
        "label": "Accuracy Tracker",
        "value": c.PAGE_URLS[c.PAGE_ACCURACY],
        "role": ["Admin"],
        component: Accuracy
      },
      {
        "label": "Sub Category",
        "value": c.PAGE_URLS[c.PAGE_SUBCATEGORY],
        "role": ["Admin"],
        component: SubCategory
      },
      {
        "label": "Confusion Matrix",
        "value": c.PAGE_URLS[c.PAGE_CONFUSION],
        "role": ["Admin"],
        component: ConfusionMatrix
      },
      {
        "label": "Doc Info",
        "value": c.PAGE_URLS[c.PAGE_DOC_INFO],
        "role": ["Admin"],
        component: DocInfo
      },
    ]
  },
]

window.MENU_OPTIONS = MENU_OPTIONS;
