import { createStore, applyMiddleware} from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from '../root-reducer';
import { composeWithDevTools } from "@redux-devtools/extension";

const configureStore = ((initialState) => {
    const middleware = applyMiddleware(thunk);
    return createStore(
    rootReducer,
    undefined,
    composeWithDevTools(middleware)
    );
});

export default configureStore;
