import { DeepClone } from "commonFunctions/commonFunctions";
import { GetRoutingData, } from "components/NavigationMenu/helper";
import NavigationMenu from "components/NavigationMenu/NavigationMenu";
import NotFound from "components/NotFound/NotFound";
import InfoScreen from "pages/InfoScreen/InfoScreen";
import React, { useEffect, useState, lazy } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { MENU_OPTIONS } from "utils/constants/NavigationOptions";
import { getGnrlSettings } from "../redux/generalSettings/actions/actions";
import { getGnrlSettingsActions } from "../redux/generalSettings/actions/actionCreators";
import { GetUserInfo } from "../commonFunctions/commonFunctions";
import { forceLogout } from "../commonFunctions/forceLogout";
import api from "../utils/API/api";
import * as c from "../utils/constants/constants";
import * as msg from "../utils/constants/messages";
import { connect } from 'react-redux';
import { CircularProgress } from "@mui/material";

const Login = lazy(() => import('../pages/Login/Login'));
const SSORedirect = lazy(() => import('../pages/SSORedirect/SSORedirect'));
const SSOLanding = lazy(() => import('../pages/SSOLanding/SSOLanding'));
const CheckIdleTime = lazy(() => import('../container/CheckIdleTime'));
const PatientCreate = lazy(() => import('../pages/PatientCreate/PatientCreate'));

const patientPopupVisiblePages = [c.PAGE_URLS[c.PAGE_DASHBOARD], c.PAGE_URLS[c.PAGE_DOC_MGMT],
c.PAGE_URLS[c.PAGE_USER_MGMT], c.PAGE_URLS[c.PAGE_MANUAL_REVIEW],
c.PAGE_URLS[c.PAGE_AUDIT_LOG], c.PAGE_URLS[c.PAGE_USER_PROFILE],
c.PAGE_URLS[c.PAGE_REPORTS], c.PAGE_URLS[c.PAGE_PATIENT]]

const Entry = (props) => {
  const location = useLocation(null);
  const navigate = useNavigate();
  const [currentLocation, updateCurrentLocation] = useState("");
  const [routes, updateRoutes] = useState([]);
  const [publicIP, updatePublicIP] = useState('');
  const [signInSuccess, updateSignInSuccess] = useState("");
  const [loginLoading, updateLoginLoading] = useState(false);

  useEffect(() => {
    if(localStorage.getItem(c.ACCESS_TOKEN)) {
      GetRoutes();
    }
  }, [props.settings]);

  useEffect(() => {
    if(!localStorage.getItem(c.ACCESS_TOKEN)) {
      if(c.SSO === "true") {
        redirectToSSO();
      } else getIpAddress();
    }
  }, []);

  useEffect(() => {
    if(signInSuccess) {
      navigateToDashboard();
    }
  }, [signInSuccess])

  useEffect(() => {
    updateCurrentLocation(window.location?.pathname);
  }, [location]);

  const GetRoutes = () => {
    let menuOptions = MENU_OPTIONS.map(obj => DeepClone(obj));
    let routingData = GetRoutingData(menuOptions);
    updateRoutes(routingData);
  }

  const getIpAddress = () => {
    api.get_ip().then((response) => {
      updatePublicIP(response.data.ip);
    })
  }

  const getSSOAuthToken = (sso_id) => {
    localStorage.setItem(c.SSO_ID, sso_id);
    api.getAuthToken(sso_id)
    .then(res => {
      storeAuthTokens(res.data?.data[0]?.auth_token, res.data?.data[0]?.refresh_token)
    })
    .catch(error => {
      console.log(error);
      window.alert('Firebase authentication error. ' + error.message);
    })
  }

  const redirectToSSO = () => {
    let path = window.location.href.split("?");
    if (path[1]) {
      /*checking if the present URL is coming from microsoft login - for security purpose*/
      if (document.referrer.toString().indexOf("login.microsoftonline.com") === -1) {
        setTimeout(() => {
          navigate(`/${c.PAGE_URLS[c.SSO_REDIRECT]}`);
        }, 2000);
      } else {
        let path1 = path[1].split("=");
        let path2 = path1[1];
        getSSOAuthToken(path2);
      }
    } else navigate(`/${c.PAGE_URLS[c.SSO_REDIRECT]}`);
  }

  const storeAuthTokens = (access, refresh) => {
    localStorage.setItem(c.ACCESS_TOKEN, access);
    localStorage.setItem(c.REFRESH_TOKEN, refresh);
    localStorage.setItem(c.USER_ROLE, GetUserInfo(access).roles[0]);
    localStorage.setItem(c.USER_ID, GetUserInfo(refresh).user_id);
    getGeneralSettings()
  }

  const handleLogin = (formData) => {
    updateLoginLoading(true);
    api.login(formData, publicIP)
    .then((response) => {
      storeAuthTokens(response.data.data[0].auth_token, response.data.data[0].refresh_token);
    })
    .catch((error) => {
      updateSignInSuccess(false);
    });
  }

  const getGeneralSettings = () => {
    api.general_settings()
      .then((response) => {
        if (response.status === 200) {
          updateLoginLoading(false);
          props.updateGeneralSettings({ ...response.data.data[0], Client_Id: response.data.data[0]?.Client_Id });
          updateSignInSuccess(true);
          setTimeout(() => {
            updateSignInSuccess('')
          }, 2000)

        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          forceLogout();
        } else
          window.alert(msg.api_error);
      });
  }

  const navigateToDashboard = () => {
      setTimeout(function () {
        if(localStorage.getItem(c.USER_ROLE) === "Staff") navigate(`/${c.PAGE_URLS[c.PAGE_DATA]}/${c.PAGE_URLS[c.PAGE_DOC_MGMT]}`);
        else navigate(`/${c.PAGE_URLS[c.PAGE_DASHBOARD]}`);
      }, 1500);
  };

  return (

    <div className="page-main-container">
      {localStorage.getItem(c.SETTINGS) ? <CheckIdleTime /> : null}
      {patientPopupVisiblePages.find(m => currentLocation.includes(m)) ? <PatientCreate /> : null}
      <Routes>
        <Route
          path={`/${c.PAGE_URLS[c.PAGE_LOGIN]}`}
          element={ c.SSO === 'true' ? <CircularProgress style={{ color: "grey", margin: "40vh auto" }} /> : <Login handleSubmit={handleLogin} signInSuccess={signInSuccess}/>}
        />
        <Route
          path={`/${c.PAGE_URLS[c.SSO_REDIRECT]}`}
          element={<SSORedirect />}
        />
        <Route
          path={`/${c.PAGE_URLS[c.PAGE_INFO]}`}
          element={<InfoScreen navigate={navigate}/>}
        />
        {localStorage.getItem(c.ACCESS_TOKEN) ?
          <React.Fragment>
            {
              routes.map((m, i) => {
                const Component = m.component;
                return (<Route
                  path={m.path}
                  key={i}
                  element={<>
                    {m.hideNavigationMenu == true ? null : <NavigationMenu />}
                    <Component navigate={navigate} />
                  </>
                  }
                />)
              })
            }
          </React.Fragment>
          : <Route path="/" element={<Navigate to={`/${c.SSO === 'true' ? c.PAGE_URLS[c.SSO_REDIRECT] : c.PAGE_URLS[c.PAGE_LOGIN]}`} />} />
          }
        <Route path="*" element={<NotFound />}/>
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings?.settings || {}
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateGeneralSettings: url => dispatch(getGnrlSettingsActions.getGnrlSettingsActionsSuccess(url))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
